import React, { useState, useEffect } from 'react';

import Layout from '../../../components/layout';
import Content from '../../../components/licensed-in/content-container';

export default () => {
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };

  return (
    <Layout {...props} activePage='licensed-in' id='licensed-in-page'>
      <div className='licensed-in-page-container'>
        <Content title='SECURE INSURANCE IN UTAH' activeCard='utah' image={'/Utah_BG.jpeg'}>
          <p>
            At Apollo Insurance we provide affordable solutions for our clients seeking insurance in Texas. As consumers ourselves, we know the stress of
            witnessing rates increase on premiums this year. We listen to your needs and make sure you receive affordable insurance in Texas.
          </p>

          <span>AFFORDABLE FAMILY INSURANCE</span>

          <p>
            The health insurance industry can be a chaotic place. Trust Apollo Insurance to navigate you through the fine print and find health care plans in
            Utah. Our agents are working tirelessly to find clear and affordable solutions for you. Let us help you tackle the new challenges in the new year.
            Contact us today for a quote at (913) 279-0077 to secure insurance in Utah.
          </p>

          <p>
            We offer individual, family, disability, vision, dental, accident, critical illness, and gap insurance plans. With the increase in deductibles and
            out of pocket expenses of health insurance shifting to the consumer, gap insurance plans have become the most important and popular plan we sell
            today.
          </p>
        </Content>
      </div>
    </Layout>
  );
};
